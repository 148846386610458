<template>
  <v-row justify="center">
    <v-dialog v-model="show" scrollable>
      <v-btn
        v-if="orders.length > 1"
        color="grey"
        :style="{ top: '50%', transform: 'translateY(-50%)' }"
        dark
        absolute
        right
        fab
        @click="getOrder(next())"
      >
        <v-icon>mdi-chevron-right</v-icon>
      </v-btn>
      <v-btn
        v-if="Array.isArray(orders) && orders.length > 1"
        color="grey"
        :style="{ top: '50%', transform: 'translateY(-50%)' }"
        dark
        absolute
        left
        fab
        @click="getOrder(previous())"
      >
        <v-icon>mdi-chevron-left</v-icon>
      </v-btn>
      <v-card id="order">
        <v-card-title v-if="order.store" class="py-0">
          {{ order.store.name }}
          <v-card-subtitle>
            Order #: {{ order.storeOrderId }}
            <v-tooltip bottom>
              <template #activator="{ on, attrs }">
                <a
                  v-if="$abilities.includes('forceDelete orders')"
                  :href="apiBaseUrl + '/../nova/resources/orders/' + order.id"
                  target="_blank"
                  v-bind="attrs"
                  v-on="on"
                  ><v-icon small>mdi-link-variant</v-icon>
                </a>
              </template>
              <span>Open in Nova</span>
            </v-tooltip>
            <v-tooltip bottom>
              <template #activator="{ on, attrs }">
                <a
                  v-if="$abilities.includes('forceDelete orders')"
                  :href="
                    adminBaseUrl +
                    '/nova/resources/orders?orders_search=' +
                    order.storeOrderId
                  "
                  target="_blank"
                  v-bind="attrs"
                  v-on="on"
                  ><v-icon small>mdi-open-in-new</v-icon>
                </a>
              </template>
              <span>Open in Core</span>
            </v-tooltip>
            <v-chip class="ml-4" label small>{{ order.status }}</v-chip>
          </v-card-subtitle>
          <v-spacer />
          <v-chip
            v-for="tag in order.tags"
            :key="tag.id"
            :color="tag.color"
            class="mr-2"
            style="float: right"
            text-color="white"
            close
            @click:close="removeTag(tag)"
          >
            {{ tag.name }}
          </v-chip>
          <a
            v-if="$abilities.includes('forceDelete orders')"
            :href="apiBaseUrl + '/../nova/resources/orders/' + order.id"
            target="_blank"
            ><v-icon small>mdi-link-variant</v-icon>
          </a>
          <v-btn icon @click="show = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="pa-0">
          <form @submit.prevent="formSubmit">
            <v-tabs>
              <v-tab>Order Details</v-tab>
              <v-tab>Activity</v-tab>
              <v-tab-item :key="1">
                <v-card-text style="background-color: #f6f6f6">
                  <v-row>
                    <v-col cols="7">
                      <v-row>
                        <v-col cols="12">
                          <v-card>
                            <v-card-title>
                              Order Summary
                              <v-spacer />
                              <v-icon color="primary">mdi-shopping</v-icon>
                            </v-card-title>
                            <v-card-subtitle>
                              Order Date:
                              {{ $d(new Date(order.orderedAt), "short") }}
                            </v-card-subtitle>
                            <v-card-text>
                              <v-row>
                                <v-col cols="4" class="pt-0">
                                  <v-list two-line>
                                    <v-list-item>
                                      <v-list-item-content>
                                        <v-list-item-title>
                                          Ship By Date
                                        </v-list-item-title>
                                        <v-list-item-subtitle>
                                          {{
                                            $d(new Date(order.shipBy), "short")
                                          }}
                                        </v-list-item-subtitle>
                                      </v-list-item-content>
                                    </v-list-item>
                                    <v-list-item>
                                      <v-list-item-content>
                                        <v-list-item-title>
                                          Order Total
                                        </v-list-item-title>
                                        <v-list-item-subtitle>
                                          {{ $n(order.orderTotal, "currency") }}
                                        </v-list-item-subtitle>
                                      </v-list-item-content>
                                    </v-list-item>
                                    <v-list-item v-if="order.user">
                                      <v-list-item-content>
                                        <v-list-item-title>
                                          Order Assigned To
                                        </v-list-item-title>
                                        <v-list-item-subtitle>
                                          {{ order.user.name }}
                                        </v-list-item-subtitle>
                                      </v-list-item-content>
                                    </v-list-item>
                                  </v-list>
                                </v-col>
                                <v-col cols="1">
                                  <v-divider dense vertical></v-divider>
                                </v-col>
                                <v-col v-if="order.address" cols="7">
                                  <a
                                    style="font-size: smaller; float: right"
                                    @click.prevent="openAddressForm()"
                                    >Edit</a
                                  >
                                  <h3>Ship To</h3>
                                  <address v-if="order.address" class="my-3">
                                    {{ order.address.name }}
                                    <div class="text-uppercase">
                                      {{ order.address.address_1 }}<br />
                                      <div v-if="order.address.address_2">
                                        {{ order.address.address_2 }}<br />
                                      </div>
                                      {{ order.address.city }},
                                      {{ order.address.state }}
                                      {{ order.address.zipCode }}
                                    </div>
                                  </address>
                                  <div v-if="order.address.email">
                                    <v-icon class="pr-2" x-small
                                      >mdi-email</v-icon
                                    >
                                    {{ order.address.email }}
                                  </div>
                                  <div v-if="order.address.phoneNumber">
                                    <v-icon class="pr-2" x-small
                                      >mdi-phone</v-icon
                                    >
                                    {{ order.address.phoneNumber }}
                                  </div>
                                  <AddressValidatedBadge
                                    v-model="order.address"
                                  />
                                </v-col>
                              </v-row>
                            </v-card-text>
                          </v-card>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="12">
                          <v-card>
                            <v-list dense>
                              <v-list-group v-model="active">
                                <template #activator>
                                  <v-list-item-content>
                                    <v-list-item-title>
                                      Order Items
                                    </v-list-item-title>
                                  </v-list-item-content>
                                </template>

                                <v-list-item
                                  v-for="item in order.orderItems"
                                  :key="item.id"
                                  two-line
                                >
                                  <v-list-item-avatar
                                    color="primary lighten-1 white--text font-weight-bold"
                                  >
                                    {{ item.quantity }}
                                  </v-list-item-avatar>
                                  <v-list-item-content>
                                    <v-list-item-title v-if="item">
                                      {{ item.name }}
                                    </v-list-item-title>
                                    <v-list-item-subtitle>
                                      <span v-if="item.sku">
                                        SKU: {{ item.sku }}
                                      </span>
                                      <a
                                        v-if="
                                          $abilities.includes(
                                            'forceDelete orders'
                                          )
                                        "
                                        :href="
                                          adminBaseUrl +
                                          '/nova/resources/items?items_page=1&items_search=' +
                                          item.sku
                                        "
                                        target="_blank"
                                        ><v-icon x-small
                                          >mdi-open-in-new</v-icon
                                        >
                                      </a>
                                    </v-list-item-subtitle>
                                    <v-list-item-subtitle>
                                      <span v-if="item.location">
                                        Location: {{ item.location }}
                                      </span>
                                    </v-list-item-subtitle>
                                    <v-list-item-subtitle
                                      v-if="item.storeOrderItemId"
                                    >
                                      Store Id: {{ item.storeOrderItemId }}
                                    </v-list-item-subtitle>
                                    <v-list-item-subtitle v-if="item.shipment"
                                      >Shipment Id:
                                      {{ item.shipment.id }}
                                    </v-list-item-subtitle>
                                    <v-list-item-subtitle>
                                      <span v-if="item.box">
                                        Box: {{ item.box }}
                                      </span>
                                    </v-list-item-subtitle>
                                    <v-chip-group
                                      v-for="tag in item.tags"
                                      :key="tag.id"
                                    >
                                      <v-chip
                                        :color="tag.color"
                                        text-color="white"
                                        x-small
                                      >
                                        {{ tag.name }}
                                      </v-chip>
                                    </v-chip-group>
                                  </v-list-item-content>
                                  <v-list-item-action
                                    v-if="
                                      order.status == 'pending' &&
                                      $abilities.includes(
                                        'forceDelete order-items'
                                      )
                                    "
                                  >
                                    <OrderItemSplit
                                      :item="item"
                                      @input="getOrder"
                                    />
                                  </v-list-item-action>
                                </v-list-item>
                              </v-list-group>
                            </v-list>
                            <v-card-title>Notes</v-card-title>
                            <v-card-text>
                              <pre>{{ order.notes }}</pre>
                            </v-card-text>
                            <v-card-actions>
                              <v-spacer />
                              <v-icon
                                @click="
                                  noteOrder = order;
                                  showNotesForm = true;
                                "
                              >
                                mdi-note-edit
                              </v-icon>
                            </v-card-actions>
                          </v-card>
                        </v-col>
                      </v-row>
                    </v-col>
                    <v-col cols="5">
                      <v-card :loading="loading">
                        <v-card-title>
                          {{ order.shipments.length }} Shipment(s)
                          <v-spacer />
                          <v-icon color="primary">mdi-truck-fast</v-icon>
                        </v-card-title>
                        <v-card-text
                          class="px-1"
                          style="background-color: #f6f6f6"
                        >
                          <v-container
                            v-if="order.shipments.length"
                            class="px-1"
                          >
                            <v-list class="shipment_list py-0">
                              <v-list-group
                                v-for="shipment in order.shipments"
                                :key="shipment.id"
                                :value="order.shipments.length == 1"
                              >
                                <template #activator>
                                  <v-list-item-content>
                                    <v-list-item-title>
                                      <ShipmentBadge
                                        :shipments="[shipment]"
                                        dense
                                        class="mr-2"
                                        style="float: left; margin-top: -2px"
                                      />
                                      {{
                                        shipment.trackingId
                                          ? shipment.trackingId
                                          : "Shipment #" + shipment.id
                                      }}
                                      <a
                                        v-if="
                                          $abilities.includes(
                                            'forceDelete shipments'
                                          )
                                        "
                                        :href="
                                          apiBaseUrl +
                                          '/../nova/resources/shipments/' +
                                          shipment.id
                                        "
                                        target="_blank"
                                        ><v-icon x-small
                                          >mdi-link-variant</v-icon
                                        >
                                      </a>
                                      <v-chip
                                        v-for="tag in shipment.tags"
                                        :key="tag.id"
                                        :color="tag.color"
                                        text-color="white"
                                        x-small
                                        class="mt-1"
                                        style="float: right"
                                      >
                                        {{ tag.name }}
                                      </v-chip>
                                    </v-list-item-title>
                                  </v-list-item-content>
                                </template>

                                <v-list-item>
                                  <v-list-item-content>
                                    <ShipmentDetailSummary
                                      :id="parseInt(shipment.id)"
                                      @shipmentDeleted="shipmentDeleted"
                                    ></ShipmentDetailSummary>
                                  </v-list-item-content>
                                </v-list-item>
                              </v-list-group>
                            </v-list>
                          </v-container>
                        </v-card-text>
                        <v-card-title
                          v-if="$abilities.includes('forceDelete orders')"
                        >
                          Create Shipment
                          <v-spacer />
                          <v-btn
                            color="primary"
                            class="mr-2"
                            icon
                            @click="
                              createShipmentExpand = !createShipmentExpand
                            "
                          >
                            <v-icon>{{
                              createShipmentExpand
                                ? "mdi-chevron-up"
                                : "mdi-chevron-down"
                            }}</v-icon>
                          </v-btn>
                          <v-icon color="primary">mdi-truck-fast</v-icon>
                        </v-card-title>
                        <v-expand-transition>
                          <v-card-text
                            v-show="createShipmentExpand"
                            class="px-1"
                            style="background-color: #f6f6f6"
                          >
                            <v-container
                              v-if="$abilities.includes('forceDelete orders')"
                              class="px-1"
                            >
                              <v-card class="pa-3" elevation="0">
                                <v-row class="px-3 mt-0">
                                  <v-col cols="3">
                                    <v-text-field
                                      v-model="shipment.weight"
                                      label="weight"
                                      suffix="lb"
                                      type="number"
                                      @change="validateShipment()"
                                    ></v-text-field>
                                  </v-col>
                                  <v-col cols="3">
                                    <v-text-field
                                      v-model="shipment.length"
                                      label="length"
                                      suffix="in"
                                      type="number"
                                      @change="validateShipment()"
                                    ></v-text-field>
                                  </v-col>
                                  <v-col cols="3">
                                    <v-text-field
                                      v-model="shipment.width"
                                      label="width"
                                      suffix="in"
                                      type="number"
                                      @change="validateShipment()"
                                    ></v-text-field>
                                  </v-col>
                                  <v-col cols="3">
                                    <v-text-field
                                      v-model="shipment.height"
                                      label="height"
                                      suffix="in"
                                      type="number"
                                      @change="validateShipment()"
                                    ></v-text-field>
                                  </v-col>
                                </v-row>
                                <v-row class="mt-0">
                                  <v-col cols="12">
                                    <v-select
                                      v-model="shipment.box"
                                      label="Box"
                                      :items="[
                                        '',
                                        'A Box',
                                        'B Box',
                                        'C Box',
                                        'D Box',
                                        'E Box',
                                        'F Box',
                                        'G Box',
                                        'I Box',
                                        'L Box',
                                        'N Box',
                                        'O Box',
                                        'P Box',
                                        'Q Box',
                                        'SINGLE FILTER',
                                        'C BOX 3PK',
                                        'D2 Box',
                                        'POPUP BOX- DOUBLE',
                                        'POPUP BOX- SINGLE',
                                        'A3 BOX',
                                        'A4 BOX',
                                        'B3 BOX',
                                        'B4 BOX',
                                        '102 Box',
                                        '105 Box',
                                        '25X30X6 BOX',
                                        '30X36X6 BOX',
                                        'Parts Mailer Box',
                                        '20 X 20 Box',
                                        'Bubble Mailer Envelope',
                                        'ADDON',
                                        'Manufacturer Box',
                                        'Fedex Pak',
                                        'WHITE Pak',
                                      ]"
                                    >
                                      <template #prepend>
                                        <v-icon color="grey lighten-1" small>
                                          mdi-package-variant-closed
                                        </v-icon>
                                      </template>
                                    </v-select>
                                  </v-col>
                                </v-row>
                                <v-row class="mt-0">
                                  <v-col cols="12">
                                    <AddressSelect
                                      v-model="shipment.shipFromAddress"
                                      label="Ship From"
                                      tag="Ship From"
                                      @change="validateShipment()"
                                    />
                                  </v-col>
                                </v-row>
                                <v-row class="mt-0">
                                  <v-col class="pt-0" cols="12">
                                    <OrderItemsSelect
                                      :key="orderItemSelectKey"
                                      v-model="shipment.orderItems"
                                      :order-id="orderId"
                                      @change="
                                        resetShipmentForm();
                                        validateShipment();
                                      "
                                    />
                                  </v-col>
                                </v-row>
                                <v-row>
                                  <v-col cols="12">
                                    <v-btn
                                      style="float: right"
                                      color="primary"
                                      :disabled="!validShipment"
                                      :loading="loading"
                                      small
                                      @click="createShipment()"
                                      >Create Shipment
                                      <v-icon dark right>
                                        mdi-truck-fast
                                      </v-icon>
                                    </v-btn>
                                  </v-col>
                                </v-row>
                              </v-card>
                            </v-container>
                          </v-card-text>
                        </v-expand-transition>
                      </v-card>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-tab-item>
              <v-tab-item :key="2">
                <v-card-text style="background-color: #f6f6f6">
                  <v-row>
                    <v-col>
                      <ActivityTable
                        v-if="order"
                        subject-type="App\Models\Order"
                        :subject-id="[order.id]"
                      ></ActivityTable>
                      <ActivityTable
                        v-if="order.shipments"
                        subject-type="App\Models\Shipment"
                        :subject-id="order.shipments.map((a) => a.id)"
                      ></ActivityTable>
                      <ActivityTable
                        v-if="order.orderItems"
                        subject-type="App\Models\OrderItem"
                        :subject-id="order.orderItems.map((a) => a.id)"
                      ></ActivityTable>
                      <ActivityTable
                        v-if="order.address"
                        subject-type="App\Models\Address"
                        :subject-id="[order.address.id]"
                      ></ActivityTable>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-tab-item>
            </v-tabs>
          </form>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn small text @click="show = false"> Close </v-btn>
          <div class="flex-grow-1" />
          <v-btn
            v-if="!anyShipmentShipped"
            small
            text
            color="primary"
            @click="
              setTrackingId('');
              orderStatusDialogStatus = 'canceled';
              orderStatusDialog = true;
            "
          >
            Cancel Order
          </v-btn>
          <v-btn
            v-if="order.status != 'complete'"
            text
            small
            color="primary"
            @click="
              setTrackingId();
              orderStatusDialogStatus = 'complete';
              orderStatusDialog = true;
            "
          >
            Mark Complete
          </v-btn>
        </v-card-actions>
      </v-card>
      <AddressForm
        v-if="order.address"
        v-model="showAddressForm"
        :address-id="Number(order.address.id)"
      />
      <OrderNotesForm v-model="showNotesForm" :order="noteOrder" />
      <v-dialog v-model="orderStatusDialog" max-width="500px">
        <v-card>
          <v-card-title
            class="mb-4"
            style="color: white; background-color: orange"
          >
            <v-icon color="white" class="mr-2">mdi-alert-outline</v-icon>
            Mark order {{ orderStatusDialogStatus }}?
          </v-card-title>
          <v-card-text v-if="orderStatusDialogStatus != 'canceled'">
            <p>
              Tracking Id will be added to shipments before order is marked
              {{ orderStatusDialogStatus }}. If no Tracking Id is entered,
              shipments will be cleared.
            </p>
            <v-text-field
              v-model="orderStatusTrackingId"
              label="Tracking Id"
              hint="* required to close the order in the store"
              persistent-hint
            ></v-text-field>
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn text @click="orderStatusDialog = false"> Cancel </v-btn>
            <v-btn color="primary" text @click="updateOrderStatus(order)">
              Confirm
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-dialog>
  </v-row>
</template>

<script>
import AddressForm from "./input/AddressForm.vue";
import AddressSelect from "./input/AddressSelect.vue";
import OrderItemsSelect from "./input/OrderItemsSelect.vue";
import OrderItemSplit from "./input/OrderItemSplit.vue";
import OrderNotesForm from "./input/OrderNotesForm.vue";
import AddressValidatedBadge from "./ui/AddressValidatedBadge.vue";
import ShipmentDetailSummary from "./ui/ShipmentDetailSummary.vue";
import ShipmentBadge from "../components/ui/ShipmentBadge.vue";
import ActivityTable from "./ActivityTable.vue";

export default {
  name: "OrderDetail",
  components: {
    AddressForm,
    AddressSelect,
    OrderItemsSelect,
    OrderItemSplit,
    OrderNotesForm,
    AddressValidatedBadge,
    ShipmentDetailSummary,
    ShipmentBadge,
    ActivityTable,
  },
  props: {
    value: Boolean,
    orderId: {
      type: Number,
      default: 0,
    },
    orders: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      active: true,
      adminBaseUrl: "",
      apiBaseUrl: "",
      currentOrderIndex: 0,
      createShipmentExpand: true,
      orderStatusDialog: false,
      orderStatusDialogStatus: "",
      orderStatusTrackingId: "",
      loading: false,
      noteOrder: {},
      order: {
        orderedAt: "1111-11-11T11:11:11.111111Z",
        shipBy: "1111-11-11T11:11:11.111111Z",
        address: {
          name: "",
        },
        store: {
          name: "",
        },
        shipments: [],
        orderItems: [],
        user: {
          name: "",
        },
      },
      orderItemSelectKey: 1,
      rate: 12.34,
      shipment: {
        weight: 0,
        weightUnit: "lb",
        length: 0,
        width: 0,
        height: 0,
        sizeUnit: "inches",
        shipFromAddress: null,
        shippingService: null,
        orderItems: [],
      },
      showAddressForm: false,
      showNotesForm: false,
      validShipment: false,
    };
  },
  computed: {
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
    anyShipmentShipped() {
      let shipped = false;
      this.order.shipments.forEach((shipment) => {
        if (shipment.trackingId) {
          shipped = true;
        }
      });
      return shipped;
    },
  },
  watch: {
    orderId() {
      this.orders.forEach((order, index) => {
        if (order.id == this.orderId) {
          this.currentOrderIndex = index;
        }
      });
      this.getOrder();
    },
    showAddressForm() {
      this.getOrder();
    },
    showNotesForm: function (newValue) {
      if (!newValue) {
        this.getOrder();
      }
    },
  },
  mounted() {
    this.adminBaseUrl = process.env.VUE_APP_ADMIN_BASE_URL;
    this.apiBaseUrl = process.env.VUE_APP_API_BASE_URL;

    this.$bus.$on("refreshOrderDetail", () => {
      this.getOrder();
    });
  },
  methods: {
    next() {
      this.currentOrderIndex++;
      this.currentOrderIndex =
        this.currentOrderIndex >= this.orders.length
          ? 0
          : this.currentOrderIndex;

      return this.orders[this.currentOrderIndex].id;
    },
    previous() {
      this.currentOrderIndex--;
      this.currentOrderIndex =
        this.currentOrderIndex < 0
          ? this.orders.length - 1
          : this.currentOrderIndex;

      return this.orders[this.currentOrderIndex].id;
    },
    setTrackingId(trackingId = null) {
      if (trackingId !== null) {
        this.orderStatusTrackingId = trackingId;
      } else {
        let now = new Date();
        this.orderStatusTrackingId = "Manual " + now.toISOString();
      }
    },
    openAddressForm() {
      this.showAddressForm = true;
    },
    shipmentDeleted() {
      this.getOrder();
    },
    removeTag: async function (tag) {
      if (await this.confirmRemoveTag(tag)) {
        this.$models.Order.removeRelationships(this.order.id, "tag", [
          { type: "tags", id: tag.id },
        ])
          .then(() => {
            this.order.tags.forEach(function (orderTag, index, object) {
              if (orderTag.id === tag.id) {
                object.splice(index, 1);
              }
            });

            this.$bus.$emit(
              "Message",
              tag.name + " removed from order " + this.order.id
            );
          })
          .catch((e) => this.$bus.$emit("Error", e));
      }
    },
    confirmRemoveTag: function (tag) {
      return this.$confirm("Remove " + tag.name + "?", {
        title: "Warning",
        buttonTrueText: "Continue",
        buttonFalseText: "Cancel",
      });
    },
    validateShipment: function () {
      if (
        this.shipment.weight > 0 &&
        this.shipment.length > 0 &&
        this.shipment.width > 0 &&
        this.shipment.height > 0 &&
        "shipFromAddress" in this.shipment &&
        this.shipment.shipFromAddress
      ) {
        this.validShipment = true;
      }
    },
    getOrder(localOrderId = null) {
      this.loading = true;

      this.$models.Order.find(localOrderId ?? this.orderId, {
        include:
          "address,orderItems,orderItems.order,orderItems.shipment,orderItems.tags,shipments,shipments.tags,store,tags,user",
      })
        .then((response) => {
          this.order = response.data;
          this.orderItemSelectKey += 1;
          this.loading = false;
        })
        .catch((e) => this.$bus.$emit("Error", e));
    },
    createShipment: function () {
      this.loading = true;

      this.$models.Shipment.create({
        box: this.shipment.box,
        weight: parseFloat(this.shipment.weight),
        weightUnit: this.shipment.weightUnit,
        length: parseFloat(this.shipment.length),
        width: parseFloat(this.shipment.width),
        height: parseFloat(this.shipment.height),
        sizeUnit: this.shipment.sizeUnit,
        status: this.shipment.status,
        shippingServiceShipmentId: this.order.storeOrderId,
        order: this.order,
        shippingService: this.shipment.shippingService,
        shipFromAddress: this.shipment.shipFromAddress,
      })
        .then((response) => {
          // Attach order items
          if (this.shipment.orderItems) {
            this.loading = true;

            let orderItems = [];

            this.shipment.orderItems.forEach((orderItem) => {
              orderItems.push({ id: orderItem.id, type: "order-items" });
            });

            this.$models.Shipment.createRelationships(
              response.data.id,
              "order-item",
              orderItems
            )
              .then(() => {
                this.getOrder();
              })
              .catch((e) => this.$bus.$emit("Error", e));
          } else {
            this.getOrder();
          }

          this.loading = false;
          this.$bus.$emit("Message", "Shipment created");
        })
        .catch((e) => this.$bus.$emit("Error", e));
    },
    resetShipmentForm() {
      let { length, width, height } = this.getDimensions(
        this.shipment.orderItems
      );
      let weight = this.getWeight(this.shipment.orderItems);

      this.shipment.weight = weight;
      this.shipment.length = length;
      this.shipment.width = width;
      this.shipment.height = height;
      this.shipment.shippingService = null;

      this.shipment.shipFromAddress = localStorage.getItem(
        "defaultShipFromAddress"
      )
        ? JSON.parse(localStorage.getItem("defaultShipFromAddress"))
        : null;
    },
    getDimensions(orderItems) {
      let length = 0;
      let width = 0;
      let height = 0;

      if (orderItems) {
        orderItems.forEach((orderItem) => {
          length = orderItem.length > length ? orderItem.length : length;
          width = orderItem.width > width ? orderItem.width : width;
          height += orderItem.height * orderItem.quantity;
        });
      }

      return {
        length: length.toFixed(2),
        width: width.toFixed(2),
        height: height.toFixed(2),
      };
    },
    getWeight(orderItems) {
      let weight = 0;

      if (orderItems) {
        orderItems.forEach((orderItem) => {
          weight += orderItem.weight * orderItem.quantity;
        });
      }

      return weight.toFixed(2);
    },
    updateOrderStatus: function (order) {
      order.shipments.forEach((shipment) => {
        if (this.orderStatusTrackingId) {
          this.$models.Shipment.update(shipment.id, {
            trackingId: this.orderStatusTrackingId,
          })
            .then(() => {
              this.getOrder();
              this.$bus.$emit(
                "Message",
                "Tracking Id set: " + this.orderStatusTrackingId
              );

              let now = new Date();
              this.orderStatusTrackingId = "Manual " + now.toISOString();
            })
            .catch((e) => this.$bus.$emit("Error", e));
        } else {
          this.$models.Shipment.delete(shipment.id)
            .then(() => {
              this.getOrder();
              this.$bus.$emit(
                "Message",
                "Shipment " + shipment.id + " cleared."
              );
            })
            .catch((e) => this.$bus.$emit("Error", e));
        }
      });

      this.$models.Order.update(order.id, {
        status: this.orderStatusDialogStatus,
      })
        .then(() => {
          this.getOrder();
          this.$emit("orderStatusUpdated");
          this.$bus.$emit(
            "Message",
            "Order marked " + this.orderStatusDialogStatus + "."
          );
          this.orderStatusDialog = false;
        })
        .catch((e) => this.$bus.$emit("Error", e));
    },
  },
};
</script>

<style>
address {
  font-style: normal;
}

/* Desktop */
@media screen and (min-width: 768px) {
  .v-dialog {
    width: 75% !important;
  }
}

.v-text-field__suffix {
  font-size: x-small;
}

.shipment_list .v-list-group__header {
  border-top: 1px #cccccc solid;
}

.shipment_list .v-list-group--active {
  background: #efefef;
}
</style>
